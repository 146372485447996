(function (wc, $) {
	wc.grantValidationDefaults = function () {
		return {
			rules: {},
			messages: {},
			showErrors: function (errorMap, errorList) {

				$('#error-box').hide(0);

				if (errorList.length > 0) {
					$('#error-box').empty();
					$('#error-box').html("<p>Please correct the errors highlighted in red.</p>");
					$('#error-box').append('<ul></ul>');

					$.each(errorList, function (i) {
						$(errorList[i].element).addClass('error');
						$(errorList[i].element).parent().addClass('has-error');

						if ($(errorList[i].element).is(':radio')) {
							$(errorList[i].element).parents('.radio-group').addClass('has-error has-error--radio-group');
						}

						var message = errorList[i].message;
						if (errorList[i].message == 'This field is required.') {
							message = $(errorList[i].element).parent().find('label').text() + " is required.";
						}

						$('#error-box ul').append('<li><a class="text-danger" href="#' + errorList[i].element.name + '">' + message + '</a></li>');
					});

					$('#error-box').show(0);
					$('#error-box').addClass('alert alert-danger');
					$('html, body').animate({scrollTop: $('#error-box').offset().top}, 1000);
				}
			},
			errorClass: 'error',
			focusInvalid: false,
			focusCleanup: true,
			onfocusout: false,
			onkeyup: false,
			unhighlight: function (element, errorClass, validClass) {
				$(element).removeClass(errorClass);
				$(element).parent().removeClass('has-error');
				if ($(element).is(':radio')) {
					$(element).parents('.radio-group').removeClass('has-error has-error--radio-group');
				}
			}
		};
	};
	wc.grantStepOne = function () {
		var $container = $('.js-grant-form');
		if (!$container.length) {
			return;
		}

		var grantValidationArgs = wc.grantValidationDefaults();
		var grantValidator = $container.validate(grantValidationArgs);

		//set new rules
		grantValidator.settings.rules = null;
		grantValidator.settings.rules = {
			'page1[project_title]': 'required',
			'page1[country]': 'required',
			'page1[state]': 'required',
			'page1[district]': 'required',
			'page1[city]': 'required',
			'page1[community]': 'required',
			'page3[affected_description]': 'required',
			'page1[google_map_link]': 'required',
			'page1[languages]': 'required',
			'page1[total_amount_requested]': 'required'

		};
		//set new messages
		grantValidator.settings.messages = null;
		grantValidator.settings.messages = {
			'page1[project_title]': 'Project title required',
			'page1[country]': 'Country required',
			'page1[state]': 'State required',
			'page1[district]': 'District required',
			'page1[city]': 'City required',
			'page1[community]': 'Community required',
			'page3[affected_description]': 'Community Description required',
			'page1[languages]': 'language Spoken required',
			'page1[google_map_link]': 'Google map required',
			'page1[total_amount_requested]': 'Total request required'

		};

		grantValidator.containers.validate();
	};
	wc.grantStepTwo = function () {
		var $container = $('.js-grant-form');
		if (!$container.length) {
			return;
		}

		var grantValidationArgs = wc.grantValidationDefaults();
		var grantValidator = $container.validate(grantValidationArgs);

		//set new rules
		grantValidator.settings.rules = null;
		grantValidator.settings.rules = {
			'page2[wc_partnership_type]': 'required',
			//volunteer
			'page2[volunteer_firstname]': 'required',
			'page2[volunteer_lastname]': 'required',
			'page2[volunteer_dob]': 'required',
			'page2[volunteer_address]': 'required',
			'page2[volunteer_email]': {
				required: true,
				email: true
			},
			'page2[volunteer_phone]': 'required',
			'page2[volunteer_citystate]': 'required',
			'page2[volunteer_qualifications]': 'required',
			'page2[volunteer_sector]': 'required',
			'page2[volunteer_time_in_site]': 'required',
			'page2[volunteer_close_of_service]': 'required',
			//proj leader
			'page2[project_leader_first_name]': 'required',
			'page2[project_leader_last_name]': 'required',
			'page2[project_leader_dob]': 'required',
			'page2[project_leader_address]': 'required',
			'page2[project_leader_email]': {
				required: false,
				email: true
			},
			'page2[project_leader_phone]': 'required',
			'page2[project_leader_qualifications]': 'required',
			//community organization
			'page2[community_organization]': 'required',
			'page2[office_address]': 'required',
			'page2[phone_number]': 'required',
			'page2[year_founded]': 'required',
			'page2[years_involved]': 'required',
			'page2[mission]': 'required',
			'page2[organization_programs]': 'required',
			'page2[legally_registered_enum]': 'required',
			'page2[legally_registered]': 'required',
			'page2[elected_governing_body_enum]': 'required',
			'page2[elected_governing_body]': 'required',
			'page2[do_women_hold_50_percent_leadership]': 'required',
			'page2[do_women_hold_50_percent_leadership_enum]': 'required',
			'page2[have_bank_account_enum]': 'required',
			'page2[have_bank_account]': 'required',
			'page2[government_affiliation_enum]': 'required',
			'page2[government_affiliation]': 'required',


		};

		//new messages
		grantValidator.settings.messages = null;
		grantValidator.settings.messages = {
			'page2[wc_partnership_type]': 'Partnership type required',
			//volunteer
			'page2[volunteer_firstname]': 'Volunteer first name required',
			'page2[volunteer_lastname]': 'Volunteer last name required',
			'page2[volunteer_dob]': 'Volunteer date of birth required',
			'page2[volunteer_address]': 'Volunteer address required',
			'page2[volunteer_email]': 'Volunteer email required',
			'page2[volunteer_phone]': 'Volunteer phone number required',
			'page2[volunteer_citystate]': 'Volunteer city required',
			'page2[volunteer_qualifications]': 'Volunteer qualifications required',
			'page2[volunteer_sector]': 'Volunteer sector required',
			'page2[volunteer_time_in_site]': 'Volunteer site time required',
			'page2[volunteer_close_of_service]': 'Volunteer close of service date required',
			//project leader
			'page2[project_leader_first_name]': 'Project leader first name required',
			'page2[project_leader_last_name]': 'Project leader last name required',
			'page2[project_leader_dob]': 'Project leader date of birth required',
			'page2[project_leader_address]': 'Project leader address required',
			'page2[project_leader_email]': 'Project leader email required',
			'page2[project_leader_phone]': 'Project leader phone number required',
			'page2[project_leader_qualifications]': 'Project leader qualifications required',
			//community organization
			'page2[community_organization]': 'Community organization name required',
			'page2[office_address]': 'Community organization address required',
			'page2[phone_number]': 'Community organization phone number required',
			'page2[year_founded]': 'Community organization founded year required',
			'page2[years_involved]': 'Community organization years of involvement required',
			'page2[mission]': 'Community organization mission required',
			'page2[organization_programs]': 'Community organization programs required',
			'page2[legally_registered_enum]': 'Community organization legal status Yes/No required',
			'page2[legally_registered]': 'Community organization legal status required',
			'page2[elected_governing_body_enum]': 'Community organization election Yes/No required',
			'page2[elected_governing_body]': 'Community organization election response required',
			'page2[do_women_hold_50_percent_leadership_enum]': 'Community organization women\'s leadership Yes/No required',
			'page2[have_bank_account_enum]': 'Community organization banking Yes/No required',
			'page2[have_bank_account]': 'Community organization banking response required',
			'page2[government_affiliation_enum]': 'Community organization government affiliation Yes/No required'
		};

		grantValidator.containers.validate();
	};
	wc.grantStepThree = function () {
		var $container = $('.js-grant-form');
		if (!$container.length) {
			return;
		}

		var grantValidationArgs = wc.grantValidationDefaults();
		var grantValidator = $container.validate(grantValidationArgs);

		//set new rules
		grantValidator.settings.rules = null;
		grantValidator.settings.rules = {
			'page3[project_start_date]': 'required',
			'page3[estimated_duration]': 'required',
			'page3[community_description]': 'required',
			'page3[project_focus]': 'required',
			'page3[detailed_project_description]': 'required',
			'page3[description_of_sustainability]': 'required',
			'page3[day_in_the_life]': 'required',
			'page3[design_community_organization]': 'required',
			'page3[design_project_leader]': 'required',
			'page3[peace_corps]': 'required',
			'page3[finance_community_organization]': 'required',
			'page3[finance_project_leader]': 'required',
			'page3[peace_corp_volunteer]': 'required',
			'page3[origin_story]': 'required',
			'page3[design_community_members]': 'required',
			'page3[how_success_measured]': 'required',
		};

		//set new messages
		grantValidator.settings.messages = null;
		grantValidator.settings.messages = {
			'page3[project_start_date]': 'Project start date required',
			'page3[estimated_duration]': 'Project duration required',
			'page3[community_description]': 'Community description required',
			'page3[project_focus]': 'Project focus required',
			'page3[detailed_project_description]': 'Project description required',
			'page3[description_of_sustainability]': 'Sustainability description required',
			'page3[day_in_the_life]': 'Day in the life description required',
			'page3[design_community_organization]': 'Community organization design role response required',
			'page3[design_project_leader]': 'Project leader design role response required',
			'page3[peace_corps]': 'Peace Corp design role response required',
			'page3[finance_community_organization]': 'Community organization financial response required',
			'page3[finance_project_leader]': 'Project leader financial response required',
			'page3[peace_corp_volunteer]': 'Peace Corp financial response required',
			'page3[origin_story]': 'Origin story required',
			'page3[design_community_members]': 'Community members required',
			'page3[how_success_measured]': 'Define success required',

		};

		grantValidator.containers.validate();
	};

	wc.applicationValidation = function () {
		var $container = $('.js-grant-form');
		if (!$container.length) {
			return;
		}

		var grantValidationArgs = wc.grantValidationDefaults();
		var grantValidator = $container.validate(grantValidationArgs);

		grantValidator.containers.validate();
	};

	wc.defaultDateFormat = function () {
		const datePickerOptions = {
			altFormat: "mm/dd/yyyy",
			dateFormat: "mm/dd/yy",
			changeMonth: true,
			changeYear: true
		};

		const inputMaskOptions = {
			mask: "99/99/9999",
			alias: "date",
			placeholder: "mm/dd/yyyy",
			insertMode: false
		};


		//$(".datepicker").inputmask("99/99/9999", inputMaskOptions);
		$(".datepicker")
			.datepicker(datePickerOptions)
			.attr('readonly', 'readonly')
			.css('background-color', '#fff');
	};
}(window.wc = window.wc || {}, jQuery));
