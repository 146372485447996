(function (wc, $) {
	wc.whatsHappeningCarousel = function() {
		var $container = $('.js-carousel.carousel--whats-happening');
		if (!$container.length) {
			return;
		}
		var maxWidth = 767,
			options = {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				dots:true,
				autoplay:false,
				mobileFirst:true,
				responsive: [
					{
						breakpoint: maxWidth,
						settings: 'unslick'
					}
				]
			},
			runSlick = function() {
				$container.not('.slick-initialized').slick(options);
			};

// slick initialization while document ready
		runSlick();

		// listen to jQuery's window resize
		$(window).on('resize', function(){
			var width = $(window).width();
			if(width < maxWidth) {
				// reinit slick while window's width is less than maximum width (641px)
				runSlick();
			}
		});
	};
	wc.featuredProjectsCarousel = function() {
		$('.js-carousel.carousel--featured-projects').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots:true,
			adaptiveHeight:true,
			mobileFirst:true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						arrows: true,
					}
				}
			]
		});
	};
	wc.annualReportCarousel = function() {
		$('.js-carousel.carousel--annual-report').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots:true,
			mobileFirst:true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		});
		$('.carousel--annual-report-two').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots:true,
			mobileFirst:true,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
			]
		});
	};
	wc.singleProjectCarousel = function() {
		$('.js-carousel.carousel--project-single').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots:false,
			fade: true,
			adaptiveHeight: true
		});
		$('.js-carousel.carousel--project-single-nav').slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			arrows: false,
			dots:false,
			asNavFor: '.carousel--project-single',
			focusOnSelect: true
		});
	};
	wc.pastRecipientsCarousel = function() {
		$('.js-carousel.carousel--past-recipients').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots:true,
			mobileFirst:true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	};
	wc.simpleCarousel = function() {
		$('.js-carousel.carousel--simple').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots:false,
			mobileFirst:true,
			autoplay: true,
			fade: true,
			cssEase: 'linear',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	};
	wc.swiperInit = function() {
		const swiper = new Swiper('.swiper', {
			slidesPerView: 1,
			spaceBetween: 0,
		});
	};
}(window.wc = window.wc || {}, jQuery));
