(function (wc, $) {
	wc.translate = function () {
		var text = [];
		var fields = $(".translate, .translate-select, .translate-placeholder");

		fields.each(function(){
			if ($(this).hasClass('translate-placeholder')) {
				$(this).data('original', $(this).attr("placeholder"));

				text.push($(this).attr("placeholder"));
			}else {
				$(this).data('original', $(this).html());

				text.push($(this).html());
			}
		});

		$("#translate").on("change", function(){
			var to_lang = $(this).val();

			if (to_lang == '') {
				fields.each(function(){
					if ($(this).hasClass('translate-placeholder')) {
						$(this).attr("placeholder",($(this).data('original')));
					}else {
						$(this).html($(this).data('original'));
					}
				});
			}else {
				$.post( "/utility/translate", { language: to_lang, data: text }, function( data ) {
					$.each(data, function( index, value ) {
						if ($(fields[index]).hasClass('translate-placeholder')) {
							$(fields[index]).attr("placeholder",(value.translations[0].text));
						}else {
							$(fields[index]).html(value.translations[0].text);
						}
					});
				}, "json");
			}
		});
	};
}(window.wc = window.wc || {}, jQuery));
