/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var WC = {
		// All pages
		'common': {
			triggerMasonryGrid: function() {
				function resizeGridItem(item){
					grid = document.getElementsByClassName("js-grid")[0];
					rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
					rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
					rowSpan = Math.ceil((item.querySelector('.blog-entry').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
					item.style.gridRowEnd = "span "+rowSpan;
				}

				function resizeAllGridItems(){
					allItems = document.getElementsByClassName("js-item");
					for(x=0;x<allItems.length;x++){
						resizeGridItem(allItems[x]);
					}
				}

				window.onload = resizeAllGridItems();

				window.addEventListener("resize", resizeAllGridItems);

				allItems = document.getElementsByClassName("js-item");
				for(x=0;x<allItems.length;x++){
					imagesLoaded( allItems[x], resizeInstance);
				}

				function resizeInstance(instance){
					item = instance.elements[0];
					resizeGridItem(item);
				}
			},
			donationAmountOther: function() {


			},
			numberCounter: function() {
				$('.js-counter').each(function () {
					var $this = $(this);
					jQuery({ Counter: 0 }).animate({ Counter: $this.data('count') }, {
						duration: 4000,
						easing: 'linear',
						step: function (now) {
							$this.text(now.toLocaleString());
						}
					});
				});
			},
			donateButtonRotator: function() {
				$('.js-rotate').rotaterator({fadeSpeed:2000, pauseSpeed:100});
			},
			touchScroll: function() {
				const pointerScroll = (elem) => {

					const dragStart = (ev) => elem.setPointerCapture(ev.pointerId);
					const dragEnd = (ev) => elem.releasePointerCapture(ev.pointerId);
					const drag = (ev) => elem.hasPointerCapture(ev.pointerId) && (elem.scrollLeft -= ev.movementX);

					elem.addEventListener("pointerdown", dragStart);
					elem.addEventListener("pointerup", dragEnd);
					elem.addEventListener("pointermove", drag);
				};

				let mql = window.matchMedia("(min-width: 992px)");
				function browserChange(e) {
					if (e.matches) {
						document.querySelectorAll("[data-touch-scroll]").forEach(pointerScroll);
					}
				}

				mql.addEventListener("change", browserChange);
				browserChange(mql);
			},
			init: function() {
				// JavaScript to be fired on all pages
				WC.common.triggerMasonryGrid();
				window.wc.whatsHappeningCarousel();
				window.wc.featuredProjectsCarousel();
				window.wc.singleProjectCarousel();
				window.wc.annualReportCarousel();
				window.wc.simpleCarousel();
				window.wc.pastRecipientsCarousel();
				WC.common.donationAmountOther();
				WC.common.numberCounter();
				WC.common.donateButtonRotator();
				window.wc.defaultDateFormat();
				$('.max-words').each(function(){
					$(this).textcounter({
						type: "word",
						stopInputAtMaximum: true,
						countDown: true,
						countDownText: "Words Remaining: %d",
						max: $(this).data('max-words')
					});
				});
				WC.common.touchScroll();
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home_page': {
			init: function() {
				// JavaScript to be fired on the home page

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		//Grant application page
		'grant_step_one': {
			init: function() {
				//validation functions can be found in formValidation.js
				window.wc.grantStepOne();
				window.wc.translate();
			}
		},
		'grant_step_two': {
			init: function() {
				//validation functions can be found in formValidation.js
				window.wc.grantStepTwo();
				window.wc.translate();
			}
		},
		'grant_step_three': {
			init: function() {
				//validation functions can be found in formValidation.js
				window.wc.grantStepThree();
				window.wc.translate();
			}
		},
		'grant_step_four': {
			init: function() {
				window.wc.translate();
				window.wc.applicationValidation();
			}
		},
		'progress_step_one': {
			init: function() {
				//validation functions can be found in formValidation.js
				window.wc.applicationValidation();
			}
		},
		'final_step_one': {
			init: function() {
				//validation functions can be found in formValidation.js
				window.wc.applicationValidation();
			}
		},
		'final_step_two': {
			init: function() {
				//validation functions can be found in formValidation.js
				window.wc.applicationValidation();
			}
		},
		'final_step_three': {
			init: function() {
				//validation functions can be found in formValidation.js
				window.wc.applicationValidation();
			}
		},
		'donate_page': {
			init: function() {
				$("#billing-country").on("change",function(){
					if ($(this).val() == "US") {
						$("#billing-state-div").show();
						$("#billing-state").addClass("required");
						$("#billing-region").hide().removeClass("required");
					}else {
						$("#billing-state-div").hide();
						$("#billing-state").removeClass("required");
						$("#billing-region").show().addClass("required");
					}

					if ($(this).val() == "GB") {
						$("#billing-region").attr("placeholder","County");
						$("#billing-zip").attr("placeholder","Post Code");
					}else {
						$("#billing-region").attr("placeholder","Region");
						$("#billing-zip").attr("placeholder","Zip Code");
					}
				});

				$("#shipping-country").on("change",function(){
					if ($(this).val() == "US") {
						$("#shipping-state-div").show();
						$("#shipping-state").addClass("required");
						$("#shipping-region").hide().removeClass("required");
					}else {
						$("#shipping-state-div").hide();
						$("#shipping-state").removeClass("required");
						$("#shipping-region").show().addClass("required");
					}

					if ($(this).val() == "GB") {
						$("#shipping-region").attr("placeholder","County");
						$("#shipping-zip").attr("placeholder","Post Code");
					}else {
						$("#shipping-region").attr("placeholder","Region");
						$("#shipping-zip").attr("placeholder","Zip Code");
					}
				});

				$("#donateForm").validate({
					errorClass: 'text-danger',
					highlight: function(element, errorClass) {
						$(element).parent().addClass("has-error");
					},
					unhighlight: function(element, errorClass) {
						$(element).parent().removeClass("has-error");
					},
					errorPlacement: function(error, element) {
						//error.appendTo($("#error-block"));
						$("#error-block").html("Please fill in all fields highlighted in red");
					}
					/*,
					submitHandler: function(form) {
						grecaptcha.execute();
						form.submit();
					}*/
				});

				$("#donateForm").on("submit",function() {
					if (($("#recipient_name").val() != '' || $("#recipient_email").val() != '') && !$("#gift").is(":checked") && !$("#gift_memory").is(":checked")) {
						alert("Please check the checkbox if you would like to send this as a gift");
						return false;
					}
				});

				$('#donateForm input[name="billing[amount]"]').on('change', function() {
					var amount = $('input[name="billing[amount]"]:checked', '#donateForm').val();

					if (amount == 'other') {
						amount = $('input[name="billing[amount_other]"]').val().replace(/[^0-9-.]/g, '');
					}
					amount = parseFloat(amount);

					if (amount >= 150) {
						$(".section--gift").show();
					}else {
						$(".section--gift").hide();
					}
				});

				$('#donateForm input[name="billing[amount_other]"]').on('keyup', function() {
					var amount = $('input[name="billing[amount_other]"]').val().replace(/[^0-9-.]/g, '');
					amount = parseFloat(amount);

					if (amount >= 150) {
						$(".section--gift").show();
					}else {
						$(".section--gift").hide();
					}

					// if (amount == '' || isNaN(amount) || typeof amount == 'undefined') {
					// 	$('.other').removeClass('active');
					// 	$('#donateForm input[name="billing[amount]"]:first').trigger("click");
					// }
				});

				$("#shipping_address-checkbox").on("click", function(){
					if($(this).prop('checked')) {
						$("#shipping-info").show();
					}else {
						$("#shipping-info").hide();
					}
				});

				$('.media-group__item').on('click', function() {
					$('.media-group__item').removeClass('active');
					if(!$(this).hasClass('active')) {
						$(this).addClass('active');
					}
				});
			}
		},
		'e_card_page': {
			init: function () {
				$("#billing-country").on("change", function () {
					if ($(this).val() == "US") {
						$("#billing-state-div").show();
						$("#billing-state").addClass("required");
						$("#billing-region").hide().removeClass("required");
					} else {
						$("#billing-state-div").hide();
						$("#billing-state").removeClass("required");
						$("#billing-region").show().addClass("required");
					}

					if ($(this).val() == "GB") {
						$("#billing-region").attr("placeholder","County");
						$("#billing-zip").attr("placeholder","Post Code");
					}else {
						$("#billing-region").attr("placeholder","Region");
						$("#billing-zip").attr("placeholder","Zip Code");
					}
				});

				$("#shipping-country").on("change", function () {
					if ($(this).val() == "US") {
						$("#shipping-state-div").show();
						$("#shipping-state").addClass("required");
						$("#shipping-region").hide().removeClass("required");
					} else {
						$("#shipping-state-div").hide();
						$("#shipping-state").removeClass("required");
						$("#shipping-region").show().addClass("required");
					}

					if ($(this).val() == "GB") {
						$("#shipping-region").attr("placeholder","County");
						$("#shipping-zip").attr("placeholder","Post Code");
					}else {
						$("#shipping-region").attr("placeholder","Region");
						$("#shipping-zip").attr("placeholder","Zip Code");
					}
				});

				$("#donateForm").validate({
					errorClass: 'text-danger',
					highlight: function (element, errorClass) {
						$(element).parent().addClass("has-error");
					},
					unhighlight: function (element, errorClass) {
						$(element).parent().removeClass("has-error");
					},
					errorPlacement: function (error, element) {
						//error.appendTo($("#error-block"));
						$("#error-block").html("Please fill in all fields highlighted in red");
					}
					/*,
					submitHandler: function(form) {
						grecaptcha.execute();
						form.submit();
					}*/
				});

			}
		},
		'wc_our_projects_page': {
			init: function() {
				// Configure/customize these variables.
				var showChar = 350;  // How many characters are shown by default
				var ellipsestext = "...";
				var moretext = "<i class=\"far fa-angle-double-down text-blue fa-2x\"></i>";
				var lesstext = "<i class=\"far fa-angle-double-up text-blue fa-2x\"></i>";
				$('.more-content').each(function() {
					var content = $(this).html();

					if(content.length > showChar) {

						var c = content.substr(0, showChar);
						var h = content.substr(showChar, content.length - showChar);

						var html = c + '<span class="more-content__ellipses">' + ellipsestext+ '&nbsp;</span><span class="more-content__copy"><span>' + h + '</span><a href="" class="more-content__link center-block">' + moretext + '</a></span>';

						$(this).html(html);
					}

				});
				$(".more-content__link").on('click', function(){
					if($(this).hasClass("less")) {
						$(this).removeClass("less");
						$(this).html(moretext);
					} else {
						$(this).addClass("less");
						$(this).html(lesstext);
					}
					$(this).prev().find('.more-content__ellipses').toggle();
					$(this).prev().find('.more-content__copy span').slideToggle();
					return false;
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = WC;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
